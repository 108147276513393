#contact {
  padding: 3% 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
p {
  margin: 0 auto;
}
button {
  background: none;
  color: blue;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  display: inline;
  text-decoration: underline;
}

button:hover,
button:focus {
  text-decoration: none;
}
