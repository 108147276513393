@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: "Inter", sans-serif;
}
